/* The flip card container */
.flip-card {
  position: fixed;
  background-color: transparent;
  aspect-ratio: 3.5/2;
  width: 80vw; /* Use viewport width for responsiveness */
  height: auto; /* Let the height adjust based on the aspect ratio */
  max-width: 700px; /* Maximum width for larger screens */
  max-height: 400px; /* Maximum height for larger screens */
 
  perspective: 1000px; /* 3D effect */
  transform: translate(-50%, -50%);
  z-index: 1000; /* Ensure it's on top */
 
}

/* Inner container */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Flip effect on hover */
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

/* Front and back sides */
.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden; /* Hide back side */
  border-radius: 10px;
}

/* Front side styling */
.flip-card-front {
  background-image: url("../../assets/images/namecard.png");
  background-size: cover;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Flip content back to normal */
.flip-card-front > * {
  transform: scaleX(-1);
  height: 50%;
}

/* Back side styling */
.flip-card-back {
  background-image: url("../../assets/images/namecard.png");
  background-size: cover;
  color: black;
  transform: rotateY(180deg);
  margin: 0;
 
}

/* Heading style for back side */
.flip-card-back > h1{
  font-family: "Agustina Regular";
  height: 20%;
  font-size: calc(2.5vmin + 1vw);
  transform: translateY(10px);
}

.flip-card-back > p{
  height: 15%;
  font-size: calc(1.8vmin + 1vw);
}

.flip-card-back .contact-info {
  display: flex; /* Make contact info container a flexbox */
  align-items: center; /* Center items vertically */
  height: 10%;
  font-size: calc(1.5vmin + 0.5vw)
}

.flip-card-back .contact-info i {
  margin-right: 10px; /* Add some space between icon and text */
  margin-left: 20px;
}